import React from "react";
import { InboxOutlined } from "@ant-design/icons";
import { message, Upload } from "antd";
import { bulkUpload, uploadFile } from "./moodsApi";
const { Dragger } = Upload;

const Bulkupload = () => {
  let user = sessionStorage.getItem("userDetails");
  const token = user ? (typeof user == "object" ? user : JSON.parse(user)) : null;

  const props = {
    name: "file",
    multiple: false,
    customRequest: async (e: any) => {
      console.log("file", e);
      const formData = new FormData();

      formData.append("files", e.file);

      const res = await uploadFile(formData);
      if (res.status === 200 && res?.data) {
        message.success(`file uploaded successfully.`);
        const bulkUploadRes = await bulkUpload({ file_id: res?.data[0]?.id });
        console.log("bulkUploadRes", bulkUploadRes);

        if (bulkUploadRes.status === 200 && bulkUploadRes?.data.message) {
          message.success(`Message added successfully.`);
        }
      } else {
        message.error(` file upload failed.`);
      }
    },
  };
  return (
    <div>
      <Dragger {...props} showUploadList={false} accept="text/csv">
        <p className="ant-upload-drag-icon">
          <InboxOutlined rev={false} />
        </p>
        <p className="ant-upload-text">Click or drag file to this area to upload</p>
        <p className="ant-upload-hint">Support for a single or bulk upload. Strictly prohibited from uploading company data or other banned files.</p>
      </Dragger>
    </div>
  );
};

export default Bulkupload;
