import { APICore } from "./apiCore";

const api = new APICore();

// account
function login(params: { identifier: string; password: string }) {
  const baseUrl = "/auth/local";
  return api.create(`${baseUrl}`, params);
}

function signup(params: { fullname: string; email: string; password: string }) {
  const baseUrl = "/register/";
  return api.create(`${baseUrl}`, params);
}

function forgotPassword(params: { email: string }) {
  const baseUrl = "/forget-password/";
  return api.create(`${baseUrl}`, params);
}

export { login, signup, forgotPassword };
