const constants = {
  API: {
    getMoods: "/moods",
    getPlans: "/plans",
    getUsers: "/users",
    getMessages: "/messages",
    getCouples: "/couples",
    getSubscriptions: "/subscriptions",
    getTransactions: "/transactions",
    getAddon: "/addons",
    cronJob: "/cronjobs",
    upload: "/upload",
    bulkUpload: "/custom/bulkupload",
  },
};
export default constants;
