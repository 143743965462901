import constants from "../../constant";
import { APICore } from "../../helpers/api/apiCore";

const api = new APICore();

// Moods
const getMoods = () => {
  return api.get(constants.API.getMoods, { populate: "*" });
};

const createMoods = (param: { name: string }) => {
  return api.create(constants.API.getMoods, { data: param });
};

const updateMoods = (id: any, param: { name: string }) => {
  return api.update(constants.API.getMoods + `/${id}`, { data: param });
};

const deleteMoods = (id: Number) => {
  return api.delete(constants.API.getMoods + `/${id}`);
};

const getMassages = () => {
  return api.get(constants.API.getMessages, { populate: "*", _limit: "-1" });
};

const createMessage = (param: { name: string }) => {
  return api.create(constants.API.getMessages, { data: param });
};

const updateMessage = (id: any, param: { name: string }) => {
  return api.update(constants.API.getMessages + `/${id}`, { data: param });
};

const deleteMessage = (id: Number) => {
  return api.delete(constants.API.getMessages + `/${id}`);
};

const uploadFile = (param: any) => {
  return api.create(constants.API.upload, param);
};

const bulkUpload = (param: any) => {
  return api.create(constants.API.bulkUpload, param);
};

export { getMoods, createMoods, deleteMoods, updateMoods, getMassages, createMessage, updateMessage, deleteMessage, uploadFile, bulkUpload };
